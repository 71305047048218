import React from "react"
import "./RazacarMeaning.css"
import Container from "../../../components/UI/Container"

const RazacarMeaning = () => {
  return (
    <Container>
      <div className="razacarMeaning">
      <header>
        <h1>What is razacar?</h1>
        <p>Your Gateway to Smart, Sustainable, and Affordable Carpooling</p>
    </header>

    <main>
        <section>
            <h2>Understanding the Meaning of razacar</h2>
            <p>
                The term <strong>razacar</strong> is a creative wordplay derived from "razakar," which means "volunteer" in multiple South Asian languages. The word replaces "kar" with "car" to emphasize the logistical aspect of the platform. This clever blend signifies a community-driven approach where individuals volunteer to share rides, making transportation more efficient and accessible.
            </p>
            <p>
                Additionally, the styling of <strong>razacar</strong> should always be written in all lowercase to maintain consistency and reflect the brand's approachable and user-friendly identity.
            </p>
        </section>

        <section>
            <h2>The Vision Behind razacar</h2>
            <p>
                At its core, razacar aims to solve the daily challenges of urban mobility by creating a platform where:
                <ul>
                    <li>Drivers can share empty seats in their vehicles.</li>
                    <li>Riders can find affordable, reliable transportation.</li>
                    <li>Communities can reduce their carbon footprint by sharing resources.</li>
                </ul>
                Our vision is to make commuting more accessible, environmentally sustainable, and socially connected.
            </p>
        </section>

        <section>
            <h2>Why Choose razacar?</h2>
            <p>
                razacar stands out for its commitment to innovation and user-centric design. Here’s why razacar is the future of transportation:
                <ol>
                    <li><strong>Eco-Friendly:</strong> By promoting carpooling, we help reduce traffic congestion and emissions.</li>
                    <li><strong>Affordable:</strong> Share ride costs with others and save money compared to traditional transportation.</li>
                    <li><strong>Convenient:</strong> Easily find rides and plan your commute through our intuitive platform.</li>
                </ol>
            </p>
        </section>

        <section>
            <h2>How Does razacar Work?</h2>
            <p>
                Using razacar is simple and efficient:
                <ul>
                    <li>Download the app or access our website.</li>
                    <li>Create an account and specify your travel preferences.</li>
                    <li>Drivers post available rides, and riders book seats based on their needs.</li>
                    <li>Both drivers and riders benefit from secure, community-driven commuting.</li>
                </ul>
            </p>
        </section>

        <section>
            <h2>Join the razacar Community</h2>
            <p>
                By choosing razacar, you’re not just opting for a ride; you’re joining a global movement towards smarter, more sustainable transportation. Together, we can reduce costs, build stronger communities, and create a greener planet.
            </p>
        </section>
    </main>
      </div>
    </Container>
  )
}

export default RazacarMeaning
