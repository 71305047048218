import { ThemeProvider } from "styled-components"
import React from "react"
import { theme } from "../theme/appModern"
import SEO from "../components/seo"
import { ResetCSS } from "../assets/css/style"
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from "../containers/AppModern/appModern.style"
import Sticky from "react-stickynode"
import Navbar from "../containers/AppModern/Navbar"
import RazacarMeaning from "./../containers/AppModern/RazacarMeaning/RazacarMeaning"
import Footer from "../containers/AppModern/Footer"
import style from "./privacy&policy.module.css"
export default function RazacarMeaningPage() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title="Meaning of razacar" keywords={["What is razacar?", "define razacar", "definition of razacar", "razacar definition", "razacar meaning", "meaning of razacar", "meaning behind razacar", "razacar's meaning"]} />
        <ResetCSS />
        <GlobalStyle />
        <AppWrapper className={style.mainPrivacyPolicy}>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar isTrue={false} />
          </Sticky>
          <ContentWrapper>
            <RazacarMeaning />
          </ContentWrapper>
          <Footer isHomePage={false} />
        </AppWrapper>
      </>
    </ThemeProvider>
  )
}
